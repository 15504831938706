<template>
  <div>
    <div class="sideBar">
      <div class="phone">
        <img src="../../assets/main/sideBar/phone.png" alt="" />
        <div class="box">
          <div>联系电话</div>
          <div>139 - 0803 - 5545</div>
          <span class="triangle"></span>
        </div>
      </div>
      <div class="wechat">
        <img src="../../assets/main/sideBar/wechat.png" alt="" />
        <div class="box">
          <img
            src="../../assets/main/sideBar/qrCode.png"
            alt=""
            class="qrCode"
          />
          <div>扫描二维码关注我们</div>
          <span class="triangle"></span>
        </div>
      </div>
      <div class="app">
        <img src="../../assets/main/sideBar/app.png" alt="" />
        <div class="box">
          <img
            src="../../assets/main/sideBar/appDownload.png"
            alt=""
            class="qrCode"
          />
          <div>扫描二维码下载app</div>
          <span class="triangle"></span>
        </div>
      </div>
      <div class="top" @click="goTop">
        <img src="../../assets/main/sideBar/top.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {
    goTop() {
      console.log(1);
      this.$emit("goTop");
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-height: 800px) {
  .sideBar {
    top: 240px;
  }
}
@media screen and (min-height: 800px) {
  .sideBar {
    top: 530px;
  }
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.sideBar {
  width: 48px;
  height: 207px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  right: 20px;
  z-index: 999;
  div {
    width: 48px;
    height: 48px;
    background: rgba(245, 245, 245, 1);
    cursor: pointer;
  }
  .phone {
    position: relative;
    img {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    .box {
      display: none;
      width: 124px;
      height: 51px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.16);
      position: absolute;
      left: -144px;
      padding-left: 10px;
      .triangle {
        display: block;
        height: 0px;
        width: 0px;
        border: 10px solid transparent;
        border-left-color: rgba(255, 255, 255, 1);
        right: -20px;
        top: 16px;
        position: absolute;
      }
      div:nth-child(1) {
        width: 100%;
        height: 12px;
        line-height: 12px;
        margin-top: 10px;
        background: #fff;
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
      }
      div:nth-child(2) {
        width: 100%;
        height: 14px;
        line-height: 14px;
        margin-top: 5px;
        background: #fff;
        color: rgba(234, 144, 82, 1);
        font-size: 14px;
        margin-left: -2px;
      }
    }
  }
  .phone:hover {
    > img {
      content: url("../../assets/main/sideBar/colorPhone.png");
    }
    .box {
      display: block;
    }
  }
  .wechat {
    @extend .phone;
    .box {
      width: 128px;
      height: 125px;
      position: absolute;
      left: -150px;
      top: -26px;
      .qrCode {
        width: 88px;
        height: 88px;
        top: -20px;
      }
      div {
        width: 126px !important;
        color: rgba(153, 153, 153, 1) !important;
        font-size: 12px;
        position: absolute;
        bottom: 10px;
      }
      .triangle {
        display: block;
        height: 0px;
        width: 0px;
        border: 10px solid transparent;
        border-left-color: rgba(255, 255, 255, 1);
        right: -20px;
        top: 46px;
        position: absolute;
      }
    }
  }
  .app {
    @extend .phone;
    .box {
      width: 128px;
      height: 125px;
      position: absolute;
      left: -150px;
      top: -26px;
      .qrCode {
        width: 88px;
        height: 88px;
        top: -20px;
      }
      div {
        width: 126px !important;
        color: rgba(153, 153, 153, 1) !important;
        font-size: 12px;
        position: absolute;
        bottom: 10px;
      }
      .triangle {
        display: block;
        height: 0px;
        width: 0px;
        border: 10px solid transparent;
        border-left-color: rgba(255, 255, 255, 1);
        right: -20px;
        top: 46px;
        position: absolute;
      }
    }
  }
  .app:hover {
    > img {
      content: url("../../assets/main/sideBar/colorApp.png");
    }
  }
  .wechat:hover {
    > img {
      content: url("../../assets/main/sideBar/colorWechat.png");
    }
  }
  .top {
    @extend .phone;
  }
  .top:hover {
    > img {
      content: url("../../assets/main/sideBar/colorTop.png");
    }
  }
}
</style>
