<template>
  <div>
    <div class="header">
      <div class="headerMain">
        <div class="logo">
          <img src="../../assets/main/logo.png" alt="" />
        </div>
        <ul class="nav">
          <li @click="navigate(1, '/home')">
            <span :class="{ active: isActive == 1 }">首页</span>
          </li>
          <li @click="navigate(2)" class="hover">
            <span :class="{ active: isActive == 2 }">方案与服务</span>
            <div class="hoverBox">
              <div @click="navigate(2, '/service/digital')">数字化转型</div>
              <div @click="navigate(2, '/service/saas')">供应链平台</div>
              <div @click="navigate(2, '/service/trade')">数字化交易</div>
            </div>
          </li>
          <li @click="navigate(3)" class="hover">
            <span :class="{ active: isActive == 3 }">产品中心</span>
            <div class="hoverBox">
              <div @click="navigate(2, '/service/goldmatrix')">
                金箱数云平台
              </div>
              <div @click="navigate(2, '/service/company')">金箱企业通</div>
              <div @click="navigate(2, '/service/intelligent')">智能盘货台</div>
            </div>
          </li>
          <li @click="navigate(4, '/about')">
            <span :class="{ active: isActive == 4 }">关于我们</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: 1,
    };
  },

  mounted() {},
  watch: {
    $route(newval) {
      if (newval.path == "/about") {
        this.isActive = 4;
      }
    },
  },
  methods: {
    navigate(i, path) {
      this.$router.push(path);
      this.isActive = i;
      const box = document.querySelector(".hoverBox");
      box.style.display = "none";
      box.removeAttribute("style");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: rgba(37, 37, 37, 1);
  .headerMain {
    width: 1200px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 130px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nav {
      width: 336px;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      li {
        font-size: 14px;
        cursor: pointer;
        color: rgba(153, 153, 153, 1);
      }
      li:hover {
        color: rgba(234, 144, 82, 1);
        font-weight: bold;
      }
      .hover {
        position: relative;
        .hoverBox {
          position: absolute;
          top: 50px;
          left: -10px;
          width: 135px;
          height: 122px;
          background: rgba(37, 37, 37, 1);
          display: none;
          flex-direction: column;
          justify-content: space-around;
          font-size: 14px;
          color: rgba(153, 153, 153, 1);

          div {
            margin-left: 10px;
            height: 14px;
            line-height: 14px;
            font-weight: lighter !important;
          }
          div:hover {
            color: rgba(234, 144, 82, 1) !important;
          }
        }
        .hoverBox:hover {
          display: flex;
        }
      }
      .hover:hover {
        .hoverBox {
          display: flex;
        }
      }
    }
  }
}
.active {
  color: rgba(234, 144, 82, 1) !important;
  font-weight: bold;
}
</style>
