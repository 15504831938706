<template>
  <div>
    <Header></Header>
    <router-view></router-view>
    <SideBar @goTop="goTop"></SideBar>
    <Footer @goTop="goTop"></Footer>
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import SideBar from "@/components/sideBar";

export default {
  components: { Header, Footer, SideBar },

  data() {
    return {};
  },

  mounted() {},

  methods: {
    goTop() {
      const scroll = document.querySelector("div");
      let timer = setInterval(() => {
        let osTop = scroll.scrollTop;
        let isSpeed = Math.floor(-osTop / 6);
        scroll.scrollTop = osTop + isSpeed;
        if (osTop == 0) {
          clearInterval(timer);
        }
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.bigScreen {
  color: #d3d6dd;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;
  &-bg {
    width: 100%;
    height: 100%;
    //padding: 16px 16px 0 16px;
    // background-image: url("~@/assets/images/pageBg.png");
    background-color: #06041a;
    background-size: cover;
    background-position: center center;
  }
}
</style>
